import en from 'vuetify/es5/locale/en';

export default {
  ...en,
  TTAlert: {
    actions: {
      cancel: 'Cancel',
      delete: 'Delete',
      save: 'Save',
    },
  },
  AppProfileMenu: {
    sign_out: 'Exit',
  },
};
