import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

// Note: https://kazupon.github.io/vue-i18n/guide/formatting.html
// TODO: Добавить поддержку [hot-module](https://kazupon.github.io/vue-i18n/guide/hot-reload.html#advanced-example)
// TODO: Может использовать yaml|yml для файлов локализации?

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

const locale = process.env.VUE_APP_I18N_LOCALE || 'ru';
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ru';
const messages = loadLocaleMessages();

const i18n = new VueI18n({
  locale,
  fallbackLocale,
  messages,
  warnHtmlInMessage: 'warn',
});

export default i18n;
