import * as Names from '@/plugins/vue-router/routeNames';
import { uiChildren, modalChildren } from '@/plugins/vue-router/children';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: Names.R_UIKIT },
      },
    ],
  },

  {
    path: '/some',
    name: Names.R_UIKIT,
    component: () => import(/* webpackChunkName "[request]" */ '@/ui/layouts/DialogStyleLayout.vue'),
    redirect: { name: Names.R_UIKIT_INDEX },
    children: modalChildren,
  },

  {
    path: '/uikit',
    component: () => import(/* webpackChunkName "[request]" */ '@/ui/layouts/HolyGrailLayout.vue'),
    children: uiChildren,
  },

  {
    path: '/examples/grids',
    component: () => import(/* webpackChunkName "[request]" */ '@/ui/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '12-grid',
        name: Names.R_EXAMPLES_GRIDS_12,
        components: {
          default: () => import(/* webpackChunkName "[request]" */ '@/views/examples/Grid12.vue'),
        },
      },
      {
        path: '1x10x1-grid',
        name: Names.R_EXAMPLES_GRIDS_1X10X1,
        components: {
          default: () => import(/* webpackChunkName "[request]" */ '@/views/examples/Grid1x10x1.vue'),
        },
      },
      {
        path: 'platform-grid',
        name: Names.R_EXAMPLES_GRIDS_PLATFORM_GRID,
        components: {
          default: () => import(/* webpackChunkName "[request]" */ '@/views/examples/PlatformGrid.vue'),
        },
      },
      {
        path: 'platform-grid2',
        name: Names.R_EXAMPLES_GRIDS_PLATFORM_GRID2,
        components: {
          default: () => import(/* webpackChunkName "[request]" */ '@/views/examples/PlatformGrid2.vue'),
        },
      },
    ],
  },
  {
    path: `/${Names.R_UIKIT_HOLY_GRAIL_LAYOUT}`,
    component: () => import(/* webpackChunkName "[request]" */ '@/ui/layouts/HolyGrailLayout.vue'),
    children: [
      {
        path: ':id?',
        name: Names.R_UIKIT_HOLY_GRAIL_LAYOUT,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          // eslint-disable-next-line max-len,vue/max-len
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@uikit/components/TTModuleMenu/examples/TTModuleMenu.example.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/examples/Grid1x10x1.vue'),
        },
      },
    ],
  },
];

export default routes;
