<template>
  <div>
    <VOverlay
      :value="loading"
      color="rgba(255, 255, 255, 1)"
      opacity="1"
    >
      <VProgressCircular
        indeterminate
        color="accent"
      />
    </VOverlay>

    <VSnackbar
      :value="snackbar.value"
      :timeout="snackbar.timeout"
      top
      :color="snackbar.color"
      @input="toggleSnack"
    >
      {{ snackbar.message }}

      <template #action="{ attrs }">
        <VBtn
          color="white"
          text
          v-bind="attrs"
          @click="toggleSnack"
        >
          закрыть
        </VBtn>
      </template>
    </VSnackbar>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import { toggleSnack } from '@/plugins/vuex/mutationTypes';

export default {
  name: 'AppOverlayLayer',
  computed: {
    ...mapState(['snackbar', 'loading']),
  },

  methods: {
    ...mapMutations([toggleSnack]),
  },
};
</script>

<style scoped>

</style>
