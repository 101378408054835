<template>
  <Component
    :is="exampleComponent"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'TTExample',

  inheritAttrs: false,

  props: {
    file: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      exampleComponent: undefined,
    };
  },

  created() {
    this.load();
  },

  methods: {
    async load() {
      let exampleComponent = {};

      try {
        exampleComponent = await import(
          /* webpackChunkName: "[request]" */
          /* webpackMode: "lazy-once" */
          `@/ui/uikit/${this.file}.vue`
        );

        this.$emit('loaded', exampleComponent.default);
      } catch (err) {
        // TODO: добавить компонент для не успешной загрузки
        this.$emit('error', err);
      }

      this.exampleComponent = exampleComponent.default;
    },
  },
};
</script>
