import Vue from 'vue';
// @ts-ignore
import Vuetify from 'vuetify/lib/framework';
import { UserVuetifyPreset } from 'vuetify';
import ru from '@/ui/uikit/locales/ru';
import en from '@/ui/uikit/locales/en';
import TTLightTheme from '../../ui/uikit/themes/tt.light.theme';
import TTDarkTheme from '../../ui/uikit/themes/tt.dark.theme';
import icons from '../../ui/uikit/icons';
import breakpoint from '../../ui/uikit/themes/tt.breakpoint';

export const options: Partial<UserVuetifyPreset> = {
  theme: {
    default: 'light',
    themes: {
      light: TTLightTheme,
      dark: TTDarkTheme,
    },
    options: {
      // Note: IE does not support CSS Custom Properties
      customProperties: false,
    },
  },
  icons,

  lang: {
    locales: { ru, en },
    current: 'ru',
  },
  breakpoint,
};

Vue.use(Vuetify);
export default new Vuetify(options);
