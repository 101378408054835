export const R_APP = 'app';
export const R_APP_HOME = 'app.home';
export const R_SOME = 'some';
export const R_UIKIT = 'uikit';
export const R_UIKIT_INDEX = 'index';
export const R_UIKIT_BTN = 'btn';
export const R_UIKIT_ALERT = 'alert';
export const R_UIKIT_BREADCRUMBS = 'breadcrumbs';
export const R_UIKIT_NOTIFY = 'notify';
export const R_UIKIT_SNACK = 'snack';
export const R_UIKIT_BTN_MODAL = 'btn-modal';
export const R_UIKIT_CHIP = 'chip';
export const R_UIKIT_PROMPT = 'prompt';
export const R_UIKIT_TOOLBAR = 'bars/toolbar';
export const R_UIKIT_TEXTFIELD = 'textfield';
export const R_UIKIT_DATA_TABLE = 'data-table';
export const R_UIKIT_SELECT = 'select';
export const R_UIKIT_TEXTAREA = 'textarea';
export const R_UIKIT_AUTOCOMPLETE = 'autocomplete';
export const R_UIKIT_AVATAR = 'avatar';
export const R_UIKIT_GRID = 'grid';
export const R_UIKIT_TYPOGRAPHY = 'typography';
export const R_UIKIT_CHECKBOX = 'checkbox';
export const R_UIKIT_COLORS = 'colors';
export const R_UIKIT_CLASSES = 'classes';
export const R_EXAMPLES_GRIDS_12 = 'examples.grid.12';
export const R_EXAMPLES_GRIDS_1X10X1 = 'examples.grid.1x10x1';
export const R_EXAMPLES_GRIDS_PLATFORM_GRID = 'examples.grid.platform-grid';
export const R_EXAMPLES_GRIDS_PLATFORM_GRID2 = 'examples.grid.platform-grid2';
export const R_UIKIT_HOLY_GRAIL_LAYOUT = 'holy-grail-layout';
export const R_ERROR_401 = '404';
export const R_ERROR_403 = '403';
export const R_ERROR_404 = '404';
export const R_ERROR_500 = '500';
export const R_WELCOME = 'welcome';
