<template>
  <div id="App">
    <VThemeProvider>
      <RouteNamesProvider>
        <div>
          <RouterView />
          <AppOverlayLayer />
        </div>
      </RouteNamesProvider>
    </VThemeProvider>
  </div>
</template>

<script>
import RouteNamesProvider from '@/components/providers/RouteNamesProvider';
import AppOverlayLayer from '@/ui/widgets/AppOverlayLayer.vue';

export default {
  name: 'App',

  components: {
    AppOverlayLayer,
    RouteNamesProvider,
  },
};
</script>

<style src="@fortawesome/fontawesome-pro/css/all.css"></style>
<style lang="scss" src="@/styles/main.scss"></style>
