import * as Names from '@/plugins/vue-router/routeNames';

export const uiChildren = [
  {
    path: '',
    name: Names.R_UIKIT_INDEX,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/UIKit.vue'),
    },
  },
  {
    path: 'colors',
    name: Names.R_UIKIT_COLORS,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Colors.vue'),
    },
  },
  {
    path: 'btn',
    name: Names.R_UIKIT_BTN,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Btn.vue'),
    },
  },
  {
    path: 'alert',
    name: Names.R_UIKIT_ALERT,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Alert.vue'),
    },
  },
  {
    path: Names.R_UIKIT_NOTIFY,
    name: Names.R_UIKIT_NOTIFY,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Notify.vue'),
    },
  },
  {
    path: 'breadcrumbs',
    name: Names.R_UIKIT_BREADCRUMBS,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Breadcrumbs.vue'),
    },
  },
  {
    path: 'snack',
    name: Names.R_UIKIT_SNACK,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Snackbar.vue'),
    },
  },
  {
    path: Names.R_UIKIT_DATA_TABLE,
    name: Names.R_UIKIT_DATA_TABLE,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/TTable.vue'),
    },
  },
  {
    path: Names.R_UIKIT_TEXTFIELD,
    name: Names.R_UIKIT_TEXTFIELD,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Textfield.vue'),
    },
  },
  {
    path: Names.R_UIKIT_SELECT,
    name: Names.R_UIKIT_SELECT,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/SelectView.vue'),
    },
  },
  {
    path: Names.R_UIKIT_TEXTAREA,
    name: Names.R_UIKIT_TEXTAREA,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/TextareaView.vue'),
    },
  },
  {
    path: Names.R_UIKIT_AUTOCOMPLETE,
    name: Names.R_UIKIT_AUTOCOMPLETE,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Autocomplete.vue'),
    },
  },
  {
    path: 'avatar',
    name: Names.R_UIKIT_AVATAR,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Avatar.vue'),
    },
  },
  {
    path: 'bars/toolbar',
    name: Names.R_UIKIT_TOOLBAR,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Toolbar.vue'),
    },
  },
  {
    path: 'grid',
    name: Names.R_UIKIT_GRID,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Grid.vue'),
    },
  },
  {
    path: 'typography',
    name: Names.R_UIKIT_TYPOGRAPHY,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Typography.vue'),
    },
  },
  {
    path: 'checkbox',
    name: Names.R_UIKIT_CHECKBOX,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Checkbox.vue'),
    },
  },
  {
    path: 'chip',
    name: Names.R_UIKIT_CHIP,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Chip.vue'),
    },
  },
  {
    path: Names.R_UIKIT_PROMPT,
    name: Names.R_UIKIT_PROMPT,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Prompt.vue'),
    },
  },
  {
    path: Names.R_UIKIT_CLASSES,
    name: Names.R_UIKIT_CLASSES,
    components: {
      nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Classes.vue'),
    },
  },
];

export const modalChildren = [
  {
    path: '',
    name: Names.R_UIKIT_BTN_MODAL,
    components: {
      'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
      default: () => import(/* webpackChunkName "[request]" */ '@/views/uikit/Btn.vue'),
    },
  },
];
