import Vue from 'vue';
import {
  i18n, router, store, vuetify,
} from '@/plugins';
import App from '@/App.vue';

// import uikit from '@/ui/plugin';
// Vue.use(uikit);

import TTExample from '@/ui/uikit/components/TTExample.vue';
import TTAppBar from '@/ui/uikit/components/TTAppBar.vue';
import TTAutocomplete from '@/ui/uikit/components/TTAutocomplete/TTAutocomplete.vue';
import TTAlert from '@/ui/uikit/components/TTAlert/TTAlert';
import TTBreadcrumbs from '@/ui/uikit/components/TTBreadcrumbs/TTBreadcrumbs.vue';
import TTBtn from '@/ui/uikit/components/TTBtn/TTBtn.vue';
import TTTooltip from '@/ui/uikit/components/TTTooltip/TTTooltip.vue';
import TTDataTable from '@/ui/uikit/components/TTDataTable/TTDataTable.vue';
import TTNavigationDrawer from '@/ui/uikit/components/TTNavigationDrawer.vue';
import TTNotify from '@/ui/uikit/components/TTNotify/TTNotify';
import TTSnackbar from '@/ui/uikit/components/TTSnackbar.vue';
import TTSelect from '@/ui/uikit/components/TTTSelect/TTSelect.vue';
import TTTextField from '@/ui/uikit/components/TTTextField/TTTextField.vue';
import TTTextarea from '@/ui/uikit/components/TTTextarea/TTTextarea.vue';
import TTToolBar from '@/ui/uikit/components/TTToolbar.vue';
import TTToolBarTitle from '@/ui/uikit/components/TTToolbarTitle.vue';
import TTCheckbox from '@/ui/uikit/components/TTCheckbox/TTCheckbox';
import TTChip from '@/ui/uikit/components/TTChip/TTChip.vue';
import TTChipGroup from '@/ui/uikit/components/TTChipGroup/TTChipGroup.vue';
import TTRadio from '@/ui/uikit/components/TTRadio/TTRadio';
import TTRadioGroup from '@/ui/uikit/components/TTRadioGroup/TTRadioGroup.vue';
import TTModuleMenu from '@/ui/uikit/components/TTModuleMenu/TTModuleMenu.vue';
import TTView from '@/components/ui/TTView.vue';

Vue.component(TTView.name, TTView);
Vue.component(TTExample.name, TTExample);
Vue.component(TTAppBar.name, TTAppBar);
Vue.component(TTModuleMenu.name, TTModuleMenu);
Vue.component(TTAutocomplete.name, TTAutocomplete);
Vue.component(TTAppBar.name, TTAppBar);

// TODO: .name
Vue.component('TTAlert', TTAlert);
Vue.component('TTBreadcrumbs', TTBreadcrumbs);
// TODO: .name
Vue.component('TTNotify', TTNotify);
Vue.component(TTBtn.name, TTBtn);
Vue.component(TTTooltip.name, TTTooltip);
Vue.component(TTDataTable.name, TTDataTable);
Vue.component(TTNavigationDrawer.name, TTNavigationDrawer);
Vue.component(TTSelect.name, TTSelect);
Vue.component(TTSnackbar.name, TTSnackbar);
Vue.component(TTTextField.name, TTTextField);
Vue.component(TTTextarea.name, TTTextarea);
Vue.component(TTToolBar.name, TTToolBar);
Vue.component(TTToolBarTitle.name, TTToolBarTitle);
Vue.component('TTCheckbox', TTCheckbox);
Vue.component('TTChip', TTChip);
Vue.component('TTChipGroup', TTChipGroup);
Vue.component('TTRadio', TTRadio);
Vue.component('TTRadioGroup', TTRadioGroup);

Vue.config.productionTip = false;

new Vue({
  name: 'TalentTech',
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
