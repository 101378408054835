<template>
  <VContainer>
    <VRow
      class="mt-3"
      align="start"
    >
      <VCol
        class="d-none d-sm-flex"
        sm="6"
        md="1"
      >
        <!-- left buttons -->
        <!-- Note: button should be wrapped:
          <VRow>
            <VCol>
              BUTTON HERE
            </VCol>
          </VRow>
        -->
        <slot name="left" />
      </VCol>

      <VCol
        class="d-none d-sm-flex"
        sm="6"
        md="1"
        order-md="last"
      >
        <!-- right buttons -->
        <!-- Note: button should be wrapped:
          <VRow>
            <VCol>
              BUTTON HERE
            </VCol>
          </VRow>
        -->
        <slot name="right" />
      </VCol>

      <VCol
        v-if="offset"
        md="10"
        cols="12"
      >
        <!-- center content -->
        <!-- Note: content should be wrapped:
          <VRow>
            <VCol>
              CONTENT HERE
            </VCol>
          </VRow>
        -->

        <VRow no-gutters>
          <VCol
            :cols="$vuetify.breakpoint.smAndUp ? 10 : 12"
            :offset="$vuetify.breakpoint.smAndUp ? 1 : 0"
          >
            <slot />
          </VCol>
        </VRow>
      </VCol>

      <VCol
        v-else
        md="10"
        cols="12"
      >
        <!-- center content -->
        <!-- Note: content should be wrapped:
          <VRow>
            <VCol>
              CONTENT HERE
            </VCol>
          </VRow>
        -->

        <slot />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'TTView',

  props: {
    offset: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
