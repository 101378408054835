import { render, staticRenderFns } from "./TTChip.vue?vue&type=template&id=d9595d2c&"
import script from "./TTChip.vue?vue&type=script&lang=ts&"
export * from "./TTChip.vue?vue&type=script&lang=ts&"
import style0 from "./TTChip.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
